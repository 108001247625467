import {TextField} from "@mui/material";

export interface ArrayInputProps {
    onChange: (inputs: string[]) => void;
    value: string[];
    label: string;
    placeholder: string;
    error?: {
        message: string,
        idx: number,
    }
}

export default function ArrayInput({ onChange, value, label, placeholder, error }: ArrayInputProps) {
    return (
        <TextField
            label={label}
            value={value.join(" ")}
            placeholder={placeholder}
            error={error !== undefined}
            helperText={error?.message}
            onChange={(e) => {
                if (e.target.value === "") {
                    onChange([])
                } else {
                    onChange(e.target.value.split(" "))
                }
            }}
            fullWidth
        />
    );
}

