import {Avatar, Button, Grid, Paper, TextField, Typography} from "@mui/material";
import LockIcon from '@mui/icons-material/Lock';
import {useImmer} from "use-immer";

export function SignIn({signIn}: {signIn: (apiKey: string) => void}) {
    const paperStyle = {
        width: 300,
        padding: '20px 15px',
    }

    const signInIconStyle = {
        backgroundColor: 'green'
    }

    const [apiKeyInput, setApiKeyInput] = useImmer("");

    return (
        <Grid container justifyContent="center" alignItems="center"
              style={{
                  minWidth: "100%",
                  height: "100vh",
              }}
        >
            <Paper elevation={2} style={paperStyle}>
                <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
                    <Grid container item justifyContent="center" alignItems="center" spacing={5}>
                        <Grid item xs={2}>
                            <Avatar style={signInIconStyle}>
                                <LockIcon/>
                            </Avatar>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography>
                                Вход
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <TextField label="API-ключ" type="password" onChange={(e) => {
                            setApiKeyInput(e.target.value)
                        }} required fullWidth/>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" onClick={() => {
                            signIn(apiKeyInput)
                        }} fullWidth={true}>Войти</Button>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
}