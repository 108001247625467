import {useEffect} from "react";
import {Box, Modal, Typography} from "@mui/material";
import {ActiveClientCountPoint} from "../../types/agents";
import {useImmer} from "use-immer";
import {VictoryChart, VictoryLine, VictoryTheme} from "victory";
import moment from "moment";

export interface StatisticsModalProps {
    isOpen: boolean,
    close: () => void,
    agentId: string,
    fetchActiveClientsData: (agentId: string) => Promise<ActiveClientCountPoint[]>
}

export function StatisticsModal({isOpen, close, agentId, fetchActiveClientsData}: StatisticsModalProps) {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    }

    const [activeClientCountPoints, setActiveClientCountPoints] = useImmer([] as ActiveClientCountPoint[])

    useEffect(() => {
        (async () => {
            let activeClientPoints = await fetchActiveClientsData(agentId)
            setActiveClientCountPoints(activeClientPoints);
        })();
    }, [agentId, fetchActiveClientsData, setActiveClientCountPoints])

    const basicData = activeClientCountPoints.map(p => {
        return {
            x: moment(p.timePoint).utc(true).toDate(),
            y: p.count
        };
    });

    return (
        <Modal open={isOpen} onClose={() => {
            close()
        }}>
            <Box sx={{...style, width: 400}}>
                <Typography variant={"h4"}>Статистика активных клиентов</Typography>
                <VictoryChart theme={VictoryTheme.material}>
                    <VictoryLine style={{
                        data: {stroke: "#c43a31"},
                        parent: {border: "1px solid #ccc"}
                    }} data={basicData} />
                </VictoryChart>

            </Box>
        </Modal>
    )
}