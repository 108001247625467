import {AgentDTO, ClientDTO, ClientDTOOsEnum} from "../api";
import {Tag} from "./common";

export interface ClientsState {
    agentsDto: AgentDTO[],
    clientDtos: ClientDTO[],

    clients: ClientState[],

    isLoading: boolean,
    error?: string
}

export interface ClientState {
    id: string,
    username: string,
    password: string,
    readLimit: number,
    writeLimit: number,
    connectionLimit: number,
    assignedAgent?: {
        id: string,
        hostname: string,
    },
    changeIpUrl: string,
    changeIpUid: string,
    currentIp?: string,
    changeIpDelaySec: number,
    os: Os,
    tags: Tag[],
    expiresAt: string | undefined,
}

export enum Os {
    Default = 'Default',
    Android = 'Android',
    Windows = 'Windows',
    Macos = 'Macos',
}

export const osFromString = (s: ClientDTOOsEnum | undefined) => {
    if (s === undefined) {
        return Os.Default
    }

    let res;
    switch (s) {
        case 'Android':
            res = Os.Android;
            break;
        case 'Windows':
            res = Os.Windows;
            break;
        case 'Macos':
            res = Os.Macos;
            break;
        default:
            res = Os.Default;
            break;
    }
    return res;
}

export type ClientsActions = Refresh | RefreshSucc | RefreshErr
    | NewClient | NewClientSucc | NewClientErr
    | UpdateClient | UpdateClientSucc | UpdateClientErr
    | DeleteClient | DeleteClientSucc | DeleteClientErr
    | ChangeIpDelay | ChangeIpDelaySucc | ChangeIpDelayErr
    | AssignAgent | AssignAgentSucc | AssignAgentErr;

export interface Refresh {
    type: "clients/refresh"
}

export interface RefreshSucc {
    type: "clients/refresh/succ",
    payload: { clientDtos: ClientDTO[], agentDtos: AgentDTO[] }
}

export interface RefreshErr {
    type: "clients/refresh/err",
    payload: string
}


export interface NewClient {
    type: "clients/newclient"
}

export interface NewClientSucc {
    type: "clients/newclient/succ",
}

export interface NewClientErr {
    type: "clients/newclient/err",
    payload: string
}

export interface UpdateClient {
    type: "clients/updateClient"
}

export interface UpdateClientSucc {
    type: "clients/updateClient/succ",
}

export interface UpdateClientErr {
    type: "clients/updateClient/err",
    payload: string
}


export interface DeleteClient {
    type: "clients/deleteclient"
}

export interface DeleteClientSucc {
    type: "clients/deleteclient/succ",
}

export interface DeleteClientErr {
    type: "clients/deleteclient/err",
    payload: string
}


export interface ChangeIpDelay {
    type: "clients/changeipdelay"
}

export interface ChangeIpDelaySucc {
    type: "clients/changeipdelay/succ",
}

export interface ChangeIpDelayErr {
    type: "clients/changeipdelay/err",
    payload: string
}


export interface AssignAgent {
    type: "clients/assignAgent"
}

export interface AssignAgentSucc {
    type: "clients/assignAgent/succ",
}

export interface AssignAgentErr {
    type: "clients/assignAgent/err",
    payload: string
}
