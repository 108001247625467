import {Alert} from "@mui/material";

export function NotificationsBar({show, msg}: NotificationsBarProps) {
    if (show)
        return (
            <Alert severity="error">
                {msg}
            </Alert>
        )

    return null;
}

export interface NotificationsBarProps {
    show: boolean,
    msg: string

}