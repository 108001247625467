import React from 'react';
import {Route, Routes} from "react-router-dom";
import {AuthState} from "./types/auth";
import {SignIn} from "./features/sign_in/SignIn";
import {useImmer} from "use-immer";
import {Agents} from "./features/agents/Agents";
import {Clients} from "./features/clients/Clients";

function App() {
    const [authState, setAuthState] = useImmer({
        apiKey: localStorage.getItem("api-key"),
        isAuthenticated: localStorage.getItem("api-key") != null
    } as AuthState);

    const signIn = (apiKey: string) => {
        localStorage.setItem("api-key", apiKey);
        setAuthState(s => {
            s.isAuthenticated = true;
            s.apiKey = apiKey;
        });
    }

    const signOut = () => {
        setAuthState(s => {
            s.isAuthenticated = false;
            s.apiKey = null;
        });
    }

    return (
        <Routes>
            {!authState.isAuthenticated && <Route path="/*" element={<SignIn signIn={signIn}/>}/>}
            {authState.isAuthenticated && <Route path="/" element={<Agents apiKey={authState.apiKey as string} signOut={signOut}/>}/>}
            {authState.isAuthenticated && <Route path="/agents" element={<Agents apiKey={authState.apiKey as string} signOut={signOut}/>}/>}
            {authState.isAuthenticated && <Route path="/clients" element={<Clients apiKey={authState.apiKey as string} signOut={signOut}/>} /> }
        </Routes>
    );
}

export default App;
