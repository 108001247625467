import {ProxyDialog} from "./ProxyDialog";
import {Button, DialogActions, DialogContent, DialogContentText} from "@mui/material";

type ConfigDialogProps = {
    open: boolean,
    text: string,
    onClose: (res: boolean) => void,
}

export function ConfirmDialog({open, text, onClose}: ConfigDialogProps) {

    return (
        <ProxyDialog open={open}>
            <DialogContent>
                <DialogContentText>{text}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose(false)}>Отмена</Button>
                <Button onClick={() => onClose(true)}>Подвердить</Button>
            </DialogActions>
        </ProxyDialog>
    )
}