/* tslint:disable */
/* eslint-disable */
/**
 * Proxybox OpenAPI Spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ActiveClientDataDTO
 */
export interface ActiveClientDataDTO {
    /**
     * 
     * @type {number}
     * @memberof ActiveClientDataDTO
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActiveClientDataDTO
     */
    'timePoint'?: string;
}
/**
 * 
 * @export
 * @interface AgentDTO
 */
export interface AgentDTO {
    /**
     * 
     * @type {string}
     * @memberof AgentDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentDTO
     */
    'hostname': string;
    /**
     * 
     * @type {string}
     * @memberof AgentDTO
     */
    'apiKey': string;
    /**
     * 
     * @type {AgentGaugeDTO}
     * @memberof AgentDTO
     */
    'gauge'?: AgentGaugeDTO;
    /**
     * 
     * @type {string}
     * @memberof AgentDTO
     */
    'lastAgentVersion'?: string;
}
/**
 * 
 * @export
 * @interface AgentGaugeDTO
 */
export interface AgentGaugeDTO {
    /**
     * 
     * @type {number}
     * @memberof AgentGaugeDTO
     */
    'ramInUseKb'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentGaugeDTO
     */
    'ramTotalKb'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentGaugeDTO
     */
    'cpuUsagePercentage'?: number;
}
/**
 * 
 * @export
 * @interface AgentWorkloadDTO
 */
export interface AgentWorkloadDTO {
    /**
     * 
     * @type {string}
     * @memberof AgentWorkloadDTO
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof AgentWorkloadDTO
     */
    'clientCount': number;
    /**
     * 
     * @type {number}
     * @memberof AgentWorkloadDTO
     */
    'activeClientCount': number;
    /**
     * 
     * @type {number}
     * @memberof AgentWorkloadDTO
     */
    'nonexpiredClientCount': number;
    /**
     * 
     * @type {number}
     * @memberof AgentWorkloadDTO
     */
    'deviceCount': number;
    /**
     * 
     * @type {number}
     * @memberof AgentWorkloadDTO
     */
    'activeDeviceCount': number;
}
/**
 * 
 * @export
 * @interface Antenity17DTO
 */
export interface Antenity17DTO {
    /**
     * 
     * @type {Antenity17DTOAntenity17}
     * @memberof Antenity17DTO
     */
    'antenity17': Antenity17DTOAntenity17;
}
/**
 * 
 * @export
 * @interface Antenity17DTOAntenity17
 */
export interface Antenity17DTOAntenity17 {
    /**
     * 
     * @type {string}
     * @memberof Antenity17DTOAntenity17
     */
    'webAddr': string;
}
/**
 * 
 * @export
 * @interface ApiErrorDTO
 */
export interface ApiErrorDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiErrorDTO
     */
    'error'?: ApiErrorDTOErrorEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiErrorDTO
     */
    'msg'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiErrorDTO
     */
    'requestId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiErrorDTO
     */
    'time'?: string;
}

export const ApiErrorDTOErrorEnum = {
    NotFound: 'NOT_FOUND',
    BadRequest: 'BAD_REQUEST',
    InternalServerError: 'INTERNAL_SERVER_ERROR',
    Unauthorized: 'UNAUTHORIZED',
    Forbidden: 'FORBIDDEN'
} as const;

export type ApiErrorDTOErrorEnum = typeof ApiErrorDTOErrorEnum[keyof typeof ApiErrorDTOErrorEnum];

/**
 * 
 * @export
 * @interface BackdoorResponseDTO
 */
export interface BackdoorResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof BackdoorResponseDTO
     */
    'login': string;
    /**
     * 
     * @type {string}
     * @memberof BackdoorResponseDTO
     */
    'password': string;
    /**
     * 
     * @type {number}
     * @memberof BackdoorResponseDTO
     */
    'port': number;
}
/**
 * 
 * @export
 * @interface ClientDTO
 */
export interface ClientDTO {
    /**
     * 
     * @type {string}
     * @memberof ClientDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientDTO
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof ClientDTO
     */
    'password': string;
    /**
     * 
     * @type {number}
     * @memberof ClientDTO
     */
    'readLimit': number;
    /**
     * 
     * @type {number}
     * @memberof ClientDTO
     */
    'writeLimit': number;
    /**
     * 
     * @type {number}
     * @memberof ClientDTO
     */
    'connectionLimit': number;
    /**
     * 
     * @type {string}
     * @memberof ClientDTO
     */
    'changeIpUid': string;
    /**
     * 
     * @type {Array<TagDTO>}
     * @memberof ClientDTO
     */
    'tags'?: Array<TagDTO>;
    /**
     * 
     * @type {CurrentIp}
     * @memberof ClientDTO
     */
    'currentIp'?: CurrentIp;
    /**
     * 
     * @type {number}
     * @memberof ClientDTO
     */
    'changeIpDelaySec'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClientDTO
     */
    'expiresAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientDTO
     */
    'agentId': string;
    /**
     * 
     * @type {string}
     * @memberof ClientDTO
     */
    'os': ClientDTOOsEnum;
}

export const ClientDTOOsEnum = {
    Default: 'Default',
    Android: 'Android',
    Windows: 'Windows',
    Macos: 'Macos'
} as const;

export type ClientDTOOsEnum = typeof ClientDTOOsEnum[keyof typeof ClientDTOOsEnum];

/**
 * 
 * @export
 * @interface CurrentIp
 */
export interface CurrentIp {
    /**
     * 
     * @type {string}
     * @memberof CurrentIp
     */
    'lastUpdateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof CurrentIp
     */
    'currentIp'?: string;
}
/**
 * 
 * @export
 * @interface DeviceDTO
 */
export interface DeviceDTO {
    /**
     * 
     * @type {string}
     * @memberof DeviceDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDTO
     */
    'agentId': string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDTO
     */
    'bindTarget': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeviceDTO
     */
    'nameservers'?: Array<string>;
    /**
     * 
     * @type {DeviceDTODeviceConfig}
     * @memberof DeviceDTO
     */
    'deviceConfig': DeviceDTODeviceConfig;
    /**
     * 
     * @type {DeviceGaugeDTO}
     * @memberof DeviceDTO
     */
    'gauge'?: DeviceGaugeDTO;
    /**
     * 
     * @type {Array<TagDTO>}
     * @memberof DeviceDTO
     */
    'tags'?: Array<TagDTO>;
}
/**
 * @type DeviceDTODeviceConfig
 * @export
 */
export type DeviceDTODeviceConfig = Antenity17DTO | HiLink17DTO | HiLink21DTO | NoopDTO;

/**
 * 
 * @export
 * @interface DeviceGaugeDTO
 */
export interface DeviceGaugeDTO {
    /**
     * 
     * @type {string}
     * @memberof DeviceGaugeDTO
     */
    'clientId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceGaugeDTO
     */
    'currentIp'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceGaugeDTO
     */
    'state'?: DeviceGaugeDTOStateEnum;
    /**
     * 
     * @type {string}
     * @memberof DeviceGaugeDTO
     */
    'lastUpdateTime'?: string;
}

export const DeviceGaugeDTOStateEnum = {
    Available: 'Available',
    Leased: 'Leased',
    OnMaintenance: 'OnMaintenance'
} as const;

export type DeviceGaugeDTOStateEnum = typeof DeviceGaugeDTOStateEnum[keyof typeof DeviceGaugeDTOStateEnum];

/**
 * 
 * @export
 * @interface HiLink17DTO
 */
export interface HiLink17DTO {
    /**
     * 
     * @type {Antenity17DTOAntenity17}
     * @memberof HiLink17DTO
     */
    'hiLink17': Antenity17DTOAntenity17;
}
/**
 * 
 * @export
 * @interface HiLink21DTO
 */
export interface HiLink21DTO {
    /**
     * 
     * @type {HiLink21DTOHiLink21}
     * @memberof HiLink21DTO
     */
    'hiLink21': HiLink21DTOHiLink21;
}
/**
 * 
 * @export
 * @interface HiLink21DTOHiLink21
 */
export interface HiLink21DTOHiLink21 {
    /**
     * 
     * @type {string}
     * @memberof HiLink21DTOHiLink21
     */
    'webAddr': string;
    /**
     * 
     * @type {string}
     * @memberof HiLink21DTOHiLink21
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof HiLink21DTOHiLink21
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface NoopDTO
 */
export interface NoopDTO {
    /**
     * 
     * @type {object}
     * @memberof NoopDTO
     */
    'noop': object;
}
/**
 * 
 * @export
 * @interface TagDTO
 */
export interface TagDTO {
    /**
     * 
     * @type {string}
     * @memberof TagDTO
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof TagDTO
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface TagFilterDTO
 */
export interface TagFilterDTO {
    /**
     * 
     * @type {Array<TagDTO>}
     * @memberof TagFilterDTO
     */
    'include': Array<TagDTO>;
    /**
     * 
     * @type {Array<TagDTO>}
     * @memberof TagFilterDTO
     */
    'exclude': Array<TagDTO>;
}

/**
 * AgentApi - axios parameter creator
 * @export
 */
export const AgentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AgentDTO} agentDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAgent: async (agentDTO: AgentDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentDTO' is not null or undefined
            assertParamExists('createAgent', 'agentDTO', agentDTO)
            const localVarPath = `/api/v1/agent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAgent: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAgent', 'id', id)
            const localVarPath = `/api/v1/agent/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgents: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/agent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentsWorkload: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/agent/workload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AgentDTO} agentDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgent: async (agentDTO: AgentDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentDTO' is not null or undefined
            assertParamExists('updateAgent', 'agentDTO', agentDTO)
            const localVarPath = `/api/v1/agent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgentApi - functional programming interface
 * @export
 */
export const AgentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AgentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AgentDTO} agentDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAgent(agentDTO: AgentDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAgent(agentDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAgent(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAgent(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgents(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AgentDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgents(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentsWorkload(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AgentWorkloadDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentsWorkload(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AgentDTO} agentDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAgent(agentDTO: AgentDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAgent(agentDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AgentApi - factory interface
 * @export
 */
export const AgentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AgentApiFp(configuration)
    return {
        /**
         * 
         * @param {AgentDTO} agentDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAgent(agentDTO: AgentDTO, options?: any): AxiosPromise<AgentDTO> {
            return localVarFp.createAgent(agentDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAgent(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAgent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgents(options?: any): AxiosPromise<Array<AgentDTO>> {
            return localVarFp.getAgents(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentsWorkload(options?: any): AxiosPromise<Array<AgentWorkloadDTO>> {
            return localVarFp.getAgentsWorkload(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AgentDTO} agentDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgent(agentDTO: AgentDTO, options?: any): AxiosPromise<AgentDTO> {
            return localVarFp.updateAgent(agentDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AgentApi - object-oriented interface
 * @export
 * @class AgentApi
 * @extends {BaseAPI}
 */
export class AgentApi extends BaseAPI {
    /**
     * 
     * @param {AgentDTO} agentDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentApi
     */
    public createAgent(agentDTO: AgentDTO, options?: AxiosRequestConfig) {
        return AgentApiFp(this.configuration).createAgent(agentDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentApi
     */
    public deleteAgent(id: string, options?: AxiosRequestConfig) {
        return AgentApiFp(this.configuration).deleteAgent(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentApi
     */
    public getAgents(options?: AxiosRequestConfig) {
        return AgentApiFp(this.configuration).getAgents(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentApi
     */
    public getAgentsWorkload(options?: AxiosRequestConfig) {
        return AgentApiFp(this.configuration).getAgentsWorkload(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AgentDTO} agentDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentApi
     */
    public updateAgent(agentDTO: AgentDTO, options?: AxiosRequestConfig) {
        return AgentApiFp(this.configuration).updateAgent(agentDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClientApi - axios parameter creator
 * @export
 */
export const ClientApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ClientDTO} clientDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClient: async (clientDTO: ClientDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientDTO' is not null or undefined
            assertParamExists('createClient', 'clientDTO', clientDTO)
            const localVarPath = `/api/v1/client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClient: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteClient', 'id', id)
            const localVarPath = `/api/v1/client/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [tags] 
         * @param {string} [expiration] 
         * @param {string} [agentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClients: async (tags?: string, expiration?: string, agentId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (tags !== undefined) {
                localVarQueryParameter['tags'] = tags;
            }

            if (expiration !== undefined) {
                localVarQueryParameter['expiration'] = expiration;
            }

            if (agentId !== undefined) {
                localVarQueryParameter['agentId'] = agentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ClientDTO} clientDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClient: async (clientDTO: ClientDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientDTO' is not null or undefined
            assertParamExists('updateClient', 'clientDTO', clientDTO)
            const localVarPath = `/api/v1/client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientApi - functional programming interface
 * @export
 */
export const ClientApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ClientDTO} clientDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createClient(clientDTO: ClientDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createClient(clientDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteClient(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteClient(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [tags] 
         * @param {string} [expiration] 
         * @param {string} [agentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClients(tags?: string, expiration?: string, agentId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClientDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClients(tags, expiration, agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ClientDTO} clientDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateClient(clientDTO: ClientDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateClient(clientDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClientApi - factory interface
 * @export
 */
export const ClientApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientApiFp(configuration)
    return {
        /**
         * 
         * @param {ClientDTO} clientDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClient(clientDTO: ClientDTO, options?: any): AxiosPromise<ClientDTO> {
            return localVarFp.createClient(clientDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClient(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteClient(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [tags] 
         * @param {string} [expiration] 
         * @param {string} [agentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClients(tags?: string, expiration?: string, agentId?: string, options?: any): AxiosPromise<Array<ClientDTO>> {
            return localVarFp.getClients(tags, expiration, agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClientDTO} clientDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClient(clientDTO: ClientDTO, options?: any): AxiosPromise<ClientDTO> {
            return localVarFp.updateClient(clientDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientApi - object-oriented interface
 * @export
 * @class ClientApi
 * @extends {BaseAPI}
 */
export class ClientApi extends BaseAPI {
    /**
     * 
     * @param {ClientDTO} clientDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public createClient(clientDTO: ClientDTO, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).createClient(clientDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public deleteClient(id: string, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).deleteClient(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [tags] 
     * @param {string} [expiration] 
     * @param {string} [agentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getClients(tags?: string, expiration?: string, agentId?: string, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getClients(tags, expiration, agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientDTO} clientDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public updateClient(clientDTO: ClientDTO, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).updateClient(clientDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeviceApi - axios parameter creator
 * @export
 */
export const DeviceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DeviceDTO} deviceDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDevice: async (deviceDTO: DeviceDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceDTO' is not null or undefined
            assertParamExists('createDevice', 'deviceDTO', deviceDTO)
            const localVarPath = `/api/v1/device`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDevice: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDevice', 'id', id)
            const localVarPath = `/api/v1/device/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [agentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevices: async (agentId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/device`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (agentId !== undefined) {
                localVarQueryParameter['agentId'] = agentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openBackdoorProxy: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('openBackdoorProxy', 'id', id)
            const localVarPath = `/api/v1/device/{id}/backdoor`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reboot: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('reboot', 'id', id)
            const localVarPath = `/api/v1/device/{id}/reboot`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeviceDTO} deviceDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDevice: async (deviceDTO: DeviceDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceDTO' is not null or undefined
            assertParamExists('updateDevice', 'deviceDTO', deviceDTO)
            const localVarPath = `/api/v1/device`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviceDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeviceApi - functional programming interface
 * @export
 */
export const DeviceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeviceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DeviceDTO} deviceDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDevice(deviceDTO: DeviceDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDevice(deviceDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDevice(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDevice(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [agentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDevices(agentId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeviceDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDevices(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async openBackdoorProxy(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackdoorResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.openBackdoorProxy(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reboot(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reboot(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeviceDTO} deviceDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDevice(deviceDTO: DeviceDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDevice(deviceDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeviceApi - factory interface
 * @export
 */
export const DeviceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeviceApiFp(configuration)
    return {
        /**
         * 
         * @param {DeviceDTO} deviceDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDevice(deviceDTO: DeviceDTO, options?: any): AxiosPromise<DeviceDTO> {
            return localVarFp.createDevice(deviceDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDevice(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDevice(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [agentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevices(agentId?: string, options?: any): AxiosPromise<Array<DeviceDTO>> {
            return localVarFp.getDevices(agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openBackdoorProxy(id: string, options?: any): AxiosPromise<BackdoorResponseDTO> {
            return localVarFp.openBackdoorProxy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reboot(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.reboot(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeviceDTO} deviceDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDevice(deviceDTO: DeviceDTO, options?: any): AxiosPromise<DeviceDTO> {
            return localVarFp.updateDevice(deviceDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeviceApi - object-oriented interface
 * @export
 * @class DeviceApi
 * @extends {BaseAPI}
 */
export class DeviceApi extends BaseAPI {
    /**
     * 
     * @param {DeviceDTO} deviceDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public createDevice(deviceDTO: DeviceDTO, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).createDevice(deviceDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deleteDevice(id: string, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deleteDevice(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [agentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public getDevices(agentId?: string, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).getDevices(agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public openBackdoorProxy(id: string, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).openBackdoorProxy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public reboot(id: string, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).reboot(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeviceDTO} deviceDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public updateDevice(deviceDTO: DeviceDTO, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).updateDevice(deviceDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GeneralApi - axios parameter creator
 * @export
 */
export const GeneralApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeIp: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('changeIp', 'uuid', uuid)
            const localVarPath = `/api/v1/change-ip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (uuid !== undefined) {
                localVarQueryParameter['uuid'] = uuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GeneralApi - functional programming interface
 * @export
 */
export const GeneralApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GeneralApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeIp(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeIp(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GeneralApi - factory interface
 * @export
 */
export const GeneralApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GeneralApiFp(configuration)
    return {
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeIp(uuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.changeIp(uuid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GeneralApi - object-oriented interface
 * @export
 * @class GeneralApi
 * @extends {BaseAPI}
 */
export class GeneralApi extends BaseAPI {
    /**
     * 
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public changeIp(uuid: string, options?: AxiosRequestConfig) {
        return GeneralApiFp(this.configuration).changeIp(uuid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StatisticsApi - axios parameter creator
 * @export
 */
export const StatisticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} agentId 
         * @param {number} hours 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveClientData: async (agentId: string, hours: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getActiveClientData', 'agentId', agentId)
            // verify required parameter 'hours' is not null or undefined
            assertParamExists('getActiveClientData', 'hours', hours)
            const localVarPath = `/api/v1/statistics/active-client-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (agentId !== undefined) {
                localVarQueryParameter['agentId'] = agentId;
            }

            if (hours !== undefined) {
                localVarQueryParameter['hours'] = hours;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatisticsApi - functional programming interface
 * @export
 */
export const StatisticsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatisticsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} agentId 
         * @param {number} hours 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActiveClientData(agentId: string, hours: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActiveClientDataDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActiveClientData(agentId, hours, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StatisticsApi - factory interface
 * @export
 */
export const StatisticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatisticsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} agentId 
         * @param {number} hours 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveClientData(agentId: string, hours: number, options?: any): AxiosPromise<Array<ActiveClientDataDTO>> {
            return localVarFp.getActiveClientData(agentId, hours, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatisticsApi - object-oriented interface
 * @export
 * @class StatisticsApi
 * @extends {BaseAPI}
 */
export class StatisticsApi extends BaseAPI {
    /**
     * 
     * @param {string} agentId 
     * @param {number} hours 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public getActiveClientData(agentId: string, hours: number, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).getActiveClientData(agentId, hours, options).then((request) => request(this.axios, this.basePath));
    }
}


