import {FieldError} from "react-hook-form";

const FUNC_TAG_KEY = 'proxy_group'

export type Tag = {
    key: string,
    value: string,
}

export function isValidTagString(tag: string): boolean {
    const vs = tag.split(":");
    return (vs.length === 1 && vs[0] !== '') || (vs.length === 2 && vs[0] !== '' && vs[1] !== '')
}

export function fromPrettyTag(tag: string): Tag | null {
    const vs = tag.split(":");
    if (vs.length === 1) {
        return { key: FUNC_TAG_KEY, value: vs[0]}
    } else if (vs.length === 2) {
        return { key: vs[0], value: vs[1]}
    } else {
        return null
    }
}

export function chooseColor(tagName: string) {
    return tagColors[Math.abs(hashCode(tagName) % tagColors.length)]
}

export function prettyTag(tag: Tag): string {
    if (FUNC_TAG_KEY === tag.key) {
        return tag.value
    } else {
        return `${tag.key}:${tag.value}`
    }
}

let tagColors = [
    '#b5e61d',
    '#2e7d32',
    '#ff6d01',
    '#46bdc6',
    '#0288d1',
    '#808000',
    '#cfcf00',
    '#07217a',
    '#fbbc04',
    '#ff00ff',
    '#999999',
    '#526e8f',
    '#55acee',
    '#3b5998',
    '#ed812b',
    '#25d366',
    '#6a00ff',
    '#e71a1a',
    '#dd5c97',
    '#34a853',
    '#7f6000',
    '#000000',
    '#6d8764',
    '#1818ff',
    '#8e44ad',
    '#00aba9',
    '#76a5af',
    '#a20025',
    '#34465d',
    '#c2b696',
    '#60a917',
    '#a0522d',
    '#a4c400',
    '#cfb052',
]

function hashCode(s: string): number {
    var h = 0, l = s.length, i = 0;
    if ( l > 0 )
        while (i < l)
            h = (h << 5) - h + s.charCodeAt(i++) | 0;
    return h;
}

export function extractFirstErr(error: FieldError | undefined): { message: string, idx: number } | undefined {
    let firstErr: { message: string, idx: number  } | undefined;

    if (error !== undefined && Array.isArray(error)) {
        error.forEach((x, i) => {
            if (!!x && typeof x.message === 'string') {
                firstErr = {
                    message: x.message,
                    idx: i,
                }
            }
        })
    }

    return firstErr
}