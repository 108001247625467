import {MenuItem, SideBar} from "../side_bar/SideBar";
import StorageIcon from "@mui/icons-material/Storage";
import PersonIcon from "@mui/icons-material/Person";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import {Box, Button, Container, Select, Stack, MenuItem as MuiMenuItem} from "@mui/material";
import {NotificationsBar} from "../notifications/NotificationsBar";
import {ClientsTable} from "./ClientsTable";
import {AgentApi, ClientApi, Configuration} from "../../api";
import {useImmer, useImmerReducer} from "use-immer";
import {ClientsActions, ClientsState, Os, osFromString} from "../../types/clients";
import {useCallback, useEffect, useMemo, useState} from "react";
import {NewClientModal} from "./NewClientModal";
import {v4} from "uuid";
import _ from "lodash";
import moment from "moment";
import {DATETIME_FORMAT} from "./utils";
import {Tag} from "../../types/common";

interface ClientsProps {
    signOut: () => void,
    apiKey: string
}

type SelectedAgent = {
    id: string,
}
type AgentSelection = "all" | "unassigned" | SelectedAgent

function getSelected(as: AgentSelection) {
    if (as === "all" || as === "unassigned") {
        return as;
    } else {
        return as.id;
    }
}

function toAgentSelection(as: string | null): AgentSelection {
    if (as === "all" || as === "unassigned") {
        return as
    } else if (as === null) {
        return "all"
    } else {
        return { id: as }
    }
}

export function Clients({signOut, apiKey}: ClientsProps) {
    const basePath = process.env.REACT_APP_HOSTNAME_VAR;

    const agentApi = useMemo(() => new AgentApi(new Configuration({
        basePath,
        apiKey: apiKey
    })), [basePath, apiKey]);

    const clientApi = useMemo(() => new ClientApi(new Configuration({
        basePath,
        apiKey: apiKey
    })), [basePath, apiKey])

    let [state, dispatch] = useImmerReducer<ClientsState, ClientsActions>((st, action) => {
        switch (action.type) {
            case "clients/refresh":
                st.isLoading = true;
                break;
            case "clients/refresh/succ":
                st.agentsDto = action.payload.agentDtos;
                st.clientDtos = action.payload.clientDtos;

                st.clients = action.payload.clientDtos.map(cl => {
                    let maybeAgent: {id: string, hostname: string} | undefined = undefined;
                    if (cl.agentId) {
                        let mbAgent = action.payload.agentDtos.find(agent => agent.id === cl.agentId)
                        if (mbAgent) {
                            maybeAgent = {
                                id: mbAgent.id as string, // comes from server thus it has id assigned
                                hostname: mbAgent.hostname,
                            }
                        }
                    }

                    return {
                        id: cl.id as string,
                        username: cl.username,
                        password: cl.password,
                        readLimit: cl.readLimit,
                        writeLimit: cl.writeLimit,
                        connectionLimit: cl.connectionLimit,
                        assignedAgent: maybeAgent,
                        changeIpUrl: `https://reboot.connect-uasocks.net/change-ip?uuid=${cl.changeIpUid}`,
                        changeIpUid: cl.changeIpUid,
                        currentIp: cl?.currentIp?.currentIp,
                        changeIpDelaySec: cl.changeIpDelaySec as number,
                        os: osFromString(cl.os),
                        tags: cl.tags || [],
                        expiresAt: !cl.expiresAt ? undefined: moment.utc(cl.expiresAt, DATETIME_FORMAT, true).local().format(DATETIME_FORMAT),
                    }
                }).sort((a,b) => a.username.localeCompare(b.username))
                break;
            case "clients/refresh/err":
                st.agentsDto = []
                st.clientDtos = [];
                st.clients = []
                st.error = action.payload
                st.isLoading = false;
                break;


            case "clients/newclient":
                st.isLoading = true;
                break;
            case "clients/newclient/succ":
                st.isLoading = false;
                break;
            case "clients/newclient/err":
                st.error = action.payload;
                break;

            case "clients/updateClient":
                st.isLoading = true;
                break;
            case "clients/updateClient/succ":
                st.isLoading = false;
                break;
            case "clients/updateClient/err":
                st.error = action.payload;
                break;

        }
    }, {
        agentsDto: [],
        clientDtos: [],
        clients: [],
        isLoading: false
    });

    const refresh = useCallback(async () => {
        try {
            dispatch({type: "clients/refresh"});
            let clientsProm = clientApi.getClients();
            let agentsProm = agentApi.getAgents();

            const clientsRes = await clientsProm;
            const agentsRes = await agentsProm;

            dispatch({
                type: "clients/refresh/succ",
                payload: {clientDtos: clientsRes.data, agentDtos: agentsRes.data}
            })
        } catch (e) {
            dispatch({type: "clients/refresh/err", payload: "Произошла ошибка"});
        }
    }, [agentApi, clientApi, dispatch])

    const newClient = async (username: string, password: string, readLimit: number, writeLimit: number, connectionLimit: number, tags: Tag[], changeIpUid: string, os: Os, expiresAt: string | undefined, changeIpDelaySec: number | undefined, currentAgentId: string | undefined) => {
        try {
            dispatch({type: "clients/newclient"});
            if (currentAgentId === undefined) {
                throw new Error("Cannot create a client without an agent")
            }
            await clientApi.createClient({
                username: username,
                password: password,
                readLimit: readLimit,
                writeLimit: writeLimit,
                connectionLimit: connectionLimit,
                tags: tags,
                changeIpUid: changeIpUid,
                os: os,
                expiresAt: expiresAt,
                changeIpDelaySec: changeIpDelaySec,
                agentId: currentAgentId
            })

            dispatch({type: "clients/newclient/succ"});
            refresh()
        } catch (e) {
            dispatch({type: "clients/newclient/err", payload: "Произошла ошибка"});
        }
    }

    const updateClient = async (cl: {id: string, username: string, password: string, readLimit: number, writeLimit: number, connectionLimit: number, tags: Tag[], changeIpUid: string, os: Os, expiresAt: string | undefined, changeIpDelaySec: number | undefined, agentId: string | undefined}) => {
        try {
            dispatch({type: "clients/updateClient"});
            let agentId = cl.agentId;
            if (agentId === undefined) {
                throw new Error("Cannot create a client without an agent")
            }
            await clientApi.updateClient({
                ...cl,
                agentId
            })
            dispatch({type: "clients/updateClient/succ"});

            refresh()
        } catch (e) {
            dispatch({type: "clients/updateClient/err", payload: "Произошла ошибка"});
        }
    }

    const deleteClient = async (id: string) => {
        dispatch({type: "clients/deleteclient"});
        try {
            await clientApi.deleteClient(id);
            refresh();
        } catch (e) {
            dispatch({type: "clients/deleteclient/err", payload: "произошла ошибка"});
        }
    }

    const changeIpDelay = async (clientId: string, delay: number) => {
        dispatch({type: "clients/changeipdelay"});
        try {
            let maybeClientDto = state.clientDtos.find(cl => cl.id === clientId);
            if (maybeClientDto) {
                const copy = _.cloneDeep(maybeClientDto);
                copy.changeIpDelaySec = delay;
                copy.currentIp = undefined;
                await clientApi.updateClient(copy);
                dispatch({type: "clients/changeipdelay/succ"});
            }
            refresh();
        } catch (e) {
            dispatch({type: "clients/changeipdelay/err", payload: "произошла ошибка"});
        }
    }

    const assignAgent = async (clientId: string, agentId: string) => {
        dispatch({type: "clients/assignAgent"});
        try {
            let maybeClientDto = state.clientDtos.find(cl => cl.id === clientId);
            if (maybeClientDto) {
                const copy = _.cloneDeep(maybeClientDto);
                copy.currentIp = undefined;

                if (agentId === "unassign") {
                    throw new Error('Cannot unnasign agent, action is deprecated')
                } else {
                    copy.agentId = agentId
                }

                await clientApi.updateClient(copy);
                dispatch({type: "clients/assignAgent/succ"});
            }
            refresh();
        } catch (e) {
            dispatch({type: "clients/assignAgent/err", payload: "Произошла ошибка"});
        }

    }

    useEffect(() => {
        refresh();
    }, [refresh]);

    const [isNewClientModalOpen, setIsNewClientModalOpen] = useImmer(false);

    const [currentAgent, setCurrentAgent] = useState<AgentSelection>(() => {
        let ca = localStorage.getItem('current_agent');
        return toAgentSelection(ca)
    })

    useEffect(() => localStorage.setItem('current_agent', getSelected(currentAgent)), [currentAgent]);

    const menuItems: MenuItem[] = [
        {
            icon: <StorageIcon/>,
            path: "/agents",
            text: "Агенты",
            onClick: null
        },
        {
            icon: <PersonIcon/>,
            path: "/clients",
            text: "Клиенты",
            onClick: null
        },
        {
            icon: <ExitToAppIcon/>,
            path: "/",
            text: "Выход",
            onClick: () => {
                signOut();
            }
        }
    ]

    if (currentAgent !== "all" && currentAgent !== "unassigned") {
        let aid = currentAgent.id
        if (state.agentsDto.length !== 0 && !state.agentsDto.find(a => a.id === aid)) {
            setCurrentAgent("all")
        }
    }

    const selectedClients = currentAgent === "all" || state.agentsDto.length === 0
        ? state.clients
        : currentAgent === "unassigned"
            ? state.clients.filter(c => !c.assignedAgent)
            : state.clients.filter(c => c.assignedAgent?.id === currentAgent.id)

    let agents = state.agentsDto.map(a => ({id: a.id, hostname: a.hostname}))
    agents.sort((a,b) => a.hostname.localeCompare(b.hostname));

    return (
        <Box display="flex">
            <SideBar title="Proxybox" menuItems={menuItems}/>
            <NewClientModal isOpen={isNewClientModalOpen} close={() => setIsNewClientModalOpen(false)}
                            title="Добавить клиента"
                            onSend={(username, password, readLimit, writeLimit, connectionLimit, tags, os, expiresAt, changeIpDelaySec) => {
                                newClient(username, password, readLimit, writeLimit, connectionLimit, tags, v4(), os, expiresAt, changeIpDelaySec, currentAgent === 'all' || currentAgent === 'unassigned' ? undefined : currentAgent.id);
                            }}/>
            <Container maxWidth={false}>
                <div style={{ "height" : "75px", "width" : "100%", backgroundColor: "white", borderBottom: "solid", position: "fixed", padding:0, margin: 0, top: 0, "zIndex": 5}}>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                    >
                        <Button variant="contained"
                                sx={{marginY: 2}}
                                onClick={() => {
                                    setIsNewClientModalOpen(true)
                                }}>Новый клиент</Button>

                        {state.agentsDto.length > 0 &&
                            <Select
                                id="agent-select"
                                value={getSelected(currentAgent)}
                                label="Agent"
                                onChange={(se) => setCurrentAgent(toAgentSelection(se.target.value))}
                            >
                                <MuiMenuItem value="all">All</MuiMenuItem>
                                {agents.map(agent =>
                                    <MuiMenuItem key={agent.id} value={agent.id}>{agent.hostname}</MuiMenuItem>
                                )}
                            </Select>
                        }

                        <Button variant="contained"
                                sx={{marginY: 2}}
                                onClick={() => {
                                    refresh()
                                }}>Обновить</Button>
                    </Stack>
                </div>
                <div style={{ "height" : "75px", "width" : "100%",  overflow: "hidden", padding:0, margin: 0, top: 0}}>

                </div>
                <NotificationsBar show={state.error !== undefined} msg={state.error as string}/>

                <ClientsTable
                    changeIpDelay={changeIpDelay}
                    updateClient={updateClient}
                    deleteClient={(id) => {deleteClient(id)}}
                    clients={selectedClients}
                    agentDtos={state.agentsDto}
                    assignAgent={assignAgent}
                />
            </Container>
        </Box>
    );
}