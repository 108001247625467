import {Drawer, List, ListItem, ListItemIcon, ListItemText, Typography} from "@mui/material";
import {ReactNode} from "react";
import {useNavigate} from "react-router-dom";

interface MenuItem {
    path: string,
    text: string,
    icon: ReactNode
    onClick: null | (() => void)
}

interface SideBarProps {
    title: string,
    menuItems: MenuItem[]
}

function SideBar(props: SideBarProps) {
    const navigate = useNavigate();
    const sideBarWidth = "20%";

    return (
        <Drawer variant="permanent" anchor="left" PaperProps={
            {
                sx: {width: sideBarWidth}
            }
        } sx={{
            width: sideBarWidth,
            flexShrink: 0,
        }}>
            <Typography variant="h3">{props.title}</Typography>
            <List>
                {
                    props.menuItems.map((mi) => {
                        return <ListItem button key={mi.text} onClick={() => {
                            if (mi.onClick != null) {
                                mi.onClick()
                            }
                            navigate(mi.path)
                        }
                        }>
                            <ListItemIcon>{mi.icon}</ListItemIcon>
                            <ListItemText primary={mi.text}/>
                        </ListItem>
                    })
                }
            </List>
        </Drawer>
    );
}

export type {MenuItem};
export {SideBar}
