import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
} from "@mui/material";
import {useImmer} from "use-immer";
import {AgentDTO} from "../../api";
import {ProxyDialog} from "../components/ProxyDialog";

export interface AssignAgentModalProps {
    isOpen: boolean,
    onSend: (agentId: string) => void,
    close: () => void,
    agentDtos: AgentDTO[]
}
export function AssignAgentModal({isOpen, onSend, close, agentDtos}: AssignAgentModalProps) {
    let [agentId, setAgentId] = useImmer("unassign");

    return (
        <ProxyDialog open={isOpen} onClose={close}>
            <form>
                <DialogTitle>Назначить агент</DialogTitle>
                <DialogContent>
                    <Select value={agentId} onChange={(e) => {
                        setAgentId(e.target.value as string)
                    }} fullWidth>
                        {
                            agentDtos.map((ad) => {
                                return (
                                    <MenuItem key={ad.id} value={ad.id}>{ad.hostname}</MenuItem>
                                )
                            })
                        }
                    </Select>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => {
                        onSend(agentId);
                        setAgentId("unassign");
                        close();
                    }} color="success">Назначить</Button>
                </DialogActions>
            </form>
        </ProxyDialog>
    )
}