import {
    Button,
    Stack,
    InputLabel,
    TextField,
    FormControl,
    Select,
    MenuItem,
    DialogTitle, DialogActions, DialogContent
} from "@mui/material";
import {Os} from "../../types/clients";
import {SubmitHandler, useForm} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import cryptoRandomString from 'crypto-random-string';
import {bps_to_mbps, DATETIME_FORMAT, mpbs_to_bps} from "./utils";
import {DateTimePicker} from "@mui/x-date-pickers";
import moment, {Moment} from "moment";
import { Controller } from "react-hook-form";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {ProxyDialog} from "../components/ProxyDialog";
import {useEffect} from "react";
import {extractFirstErr, fromPrettyTag, isValidTagString, prettyTag, Tag} from "../../types/common";
import ArrayInput from "../components/ArrayInput";

export interface NewClientModalProps {
    title: string,
    isOpen: boolean,
    close: () => void,
    defaults?: { username: string, password: string, readLimit: number, writeLimit: number, connectionLimit: number, tags: Tag[], os: Os, expiresAt?: string, changeIpDelayMin: number },
    onSend: (username: string, password: string, readLimit: number, writeLimit: number, connectionLimit: number, tags: Tag[], os: Os, expiresAt: string | undefined, changeIpDelaySec: number | undefined) => void
}

type NewClientForm = {
    username: string,
    password: string,
    readLimit?: number,
    writeLimit?: number,
    changeIpDelayMin?: number,
    connectionLimit?: number,
    expiresAt: string | null,
    tags: string[],
    os: string,
}

const newClientModalScheme = yup.object().shape({
    username: yup.string()
        .required("Обязательное поле")
        .max(100, "Не должно привышать 100 символов")
        .min(8, "Не должно быть меньше 8 символов"),
    password: yup.string()
        .notRequired()
        .max(100, "Не должно привышать 100 символов")
        .min(8, "Не должно быть меньше 8 символов")
        .nullable()
        .transform((value) => !!value ? value : null),
    readLimit: yup.number().nullable()
        .positive("Ожидается положительно число")
        .nullable()
        .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
    writeLimit: yup.number().nullable()
        .positive("Ожидается положительно число")
        .nullable()
        .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
    connectionLimit: yup.number()
        .positive("Ожидается положительно число")
        .nullable()
        .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
    expiresAt: yup.string()
        .nullable()
        .test("expiry date", "Ожидается корректная дата", (value) => value !== "invalid date"),
    tags: yup.array()
        .of(
            yup.string()
                .min(1, "Пустая строка не может быть тегом")
                .matches(/^[a-zA-Z0-9:\s]*$/, "Тэги должны состоять только из латинских букв и цифр, разделенные пробелом или двоеточием")
                .max(150, "Длинна строки не должна привышать 150 символов")
                .test("isValid", "Каждый тег может быть быть вида tagValue или tagKey:tagValue", (tag) => tag !== undefined && isValidTagString(tag))
        ),
});

export function NewClientModal({isOpen, close, onSend, title, defaults}: NewClientModalProps) {
    const { register, handleSubmit, formState: { errors }, getValues, control, resetField } = useForm<NewClientForm>({
        resolver: yupResolver(newClientModalScheme),
        defaultValues: {
            ...defaults,
            tags: defaults?.tags.map(prettyTag) ?? [],
            os: defaults?.os ?? "Default",
            // TODO: dont use zero as a default value
            connectionLimit: defaults?.connectionLimit === 0 ? undefined: defaults?.connectionLimit,
            changeIpDelayMin: defaults?.changeIpDelayMin === 0 ? undefined: defaults?.changeIpDelayMin,
            writeLimit: defaults?.writeLimit === 0 ? undefined: bps_to_mbps(defaults?.writeLimit),
            readLimit: defaults?.readLimit === 0 ? undefined: bps_to_mbps(defaults?.readLimit),
            expiresAt: defaults?.expiresAt === undefined ? null: defaults?.expiresAt,
        }
    })

    // Update dynamically generated values
    useEffect(() => {
        resetField("password", {
            defaultValue: defaults?.password
        })
    }, [resetField, defaults?.password])

    const onSubmit: SubmitHandler<NewClientForm> = formData => {
        onSend(
            formData.username,
            formData.password === null ? cryptoRandomString({length: 8, type: 'alphanumeric'}) : formData.password,
            mpbs_to_bps(formData.readLimit) ?? 0,
            mpbs_to_bps(formData.writeLimit) ?? 0,
            formData.connectionLimit ?? 0,
            formData.tags.map(tg => fromPrettyTag(tg) as Tag), // safe as prior to submit validation is triggered
            formData.os as Os,
            formData.expiresAt == null ? undefined : moment(formData.expiresAt, DATETIME_FORMAT, true).utc().format(DATETIME_FORMAT),
            formData.changeIpDelayMin === null ? undefined : formData.changeIpDelayMin as number * 60 ,
        );
        close()
    }

    return (
        <ProxyDialog open={isOpen} onClose={close} maxWidth="xs" fullWidth={true}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <Stack alignItems="stretch" spacing={2}>
                        <TextField
                            id="outlined-basic"
                            label="username"
                            variant="outlined"
                            inputProps={register("username")}
                            error={errors.username !== undefined}
                            helperText={errors.username?.message}
                            required
                            fullWidth
                        />

                        <TextField
                            id="outlined-basic"
                            label="password"
                            variant="outlined"
                            inputProps={register("password")}
                            error={errors.password !== undefined}
                            helperText={errors.password?.message}
                            fullWidth
                        />

                        <TextField
                            id="outlined-basic"
                            label="Read limit (Mbps)"
                            variant="outlined"
                            inputProps={register("readLimit")}
                            error={errors.readLimit !== undefined}
                            helperText={errors.readLimit?.message}
                            fullWidth
                        />

                        <TextField
                            id="outlined-basic"
                            label="Write limit (Mbps)"
                            variant="outlined"
                            inputProps={register("writeLimit")}
                            error={errors.writeLimit !== undefined}
                            helperText={errors.writeLimit?.message}
                            fullWidth
                        />

                        <TextField
                            id="outlined-basic"
                            label="connection limit"
                            variant="outlined"
                            inputProps={register("connectionLimit")}
                            error={errors.connectionLimit !== undefined}
                            helperText={errors.connectionLimit?.message}
                            fullWidth
                        />

                        <TextField
                            id="outlined-basic"
                            label="Change ip period (minutes)"
                            variant="outlined"
                            inputProps={register("changeIpDelayMin")}
                            error={errors.changeIpDelayMin !== undefined}
                            helperText={errors.changeIpDelayMin?.message}
                            fullWidth
                        />

                        <FormControl>
                            <InputLabel id="test-select-label">OS Fingerprint</InputLabel>
                            <Select defaultValue={getValues("os")} label="OS Fingerprint" inputProps={register("os")} fullWidth>
                                <MenuItem value={"Default"}>Default</MenuItem>
                                <MenuItem value={"Android"}>Android</MenuItem>
                                <MenuItem value={"Windows"}>Windows</MenuItem>
                                <MenuItem value={"Macos"}>Macos</MenuItem>
                            </Select>
                        </FormControl>

                        <Controller
                            name="expiresAt"
                            control={control}
                            render={({
                                         field: { onChange, value },
                                         fieldState: { error, invalid }
                                     }) => (
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DateTimePicker
                                        label="Expiry date"
                                        disablePast={true}
                                        value={value}
                                        onChange={(value: Moment | null) => {
                                            if (value == null) {
                                                onChange(null)
                                            } else {
                                                if (value.isValid()) {
                                                    onChange(value.format(DATETIME_FORMAT))
                                                } else {
                                                    onChange("invalid date")
                                                }
                                            }
                                        }}
                                        renderInput={(params) => {
                                            return (<TextField
                                                id="outlined-basic"
                                                helperText={error?.message}
                                                variant="outlined"
                                                margin="dense"
                                                {...params}
                                                error={invalid}
                                                fullWidth
                                            />)
                                        }}
                                    />
                                </LocalizationProvider>
                            )}
                        />

                        <Controller
                            name="tags"
                            control={control}
                            render={({
                                         field: {onChange, value},
                                         fieldState: { error }
                                     }) => (
                                <ArrayInput
                                    onChange={onChange}
                                    value={value}
                                    placeholder=""
                                    label="Теги"
                                    error={extractFirstErr(error)}
                                />
                            )}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" variant="contained" color="success">Сохранить</Button>
                </DialogActions>
            </form>
        </ProxyDialog>
    )
}