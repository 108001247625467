import {AgentDTO, ClientDTO, DeviceDTO} from "../api";
import {Tag} from "./common";

export const DeviceTypeVals = {
    Noop: 'NOOP',
    HiLink17: 'HILINK_17',
    Antenity17: 'ANTENITY_17',
    HiLink21: 'HILINK_21'
} as const;

export type DeviceType = typeof DeviceTypeVals[keyof typeof DeviceTypeVals];

type DeviceTypePretty = {
    [Property in DeviceType]: string
}

export const deviceTypePretty: DeviceTypePretty = {
    [DeviceTypeVals.Noop]: "Noop",
    [DeviceTypeVals.HiLink17]: "HiLink 17",
    [DeviceTypeVals.Antenity17]: "HiLink 17A",
    [DeviceTypeVals.HiLink21]: "HiLink 21",
}

export type Antenity17 = {
    webAddr: string,
}

export type HiLink17 = {
    webAddr: string,
}

export type HiLink21 = {
    webAddr: string,
    login: string,
    password: string,
}

export type DeviceConfig =
    | { antenity17: Antenity17 }
    | { hiLink17: HiLink17 }
    | { hiLink21: HiLink21 }
    | { noop: {} }

export interface DeviceModel {
    newDevice: (agentId: string, bindTarget: string, nameservers: string[], tags: Tag[], deviceConfig: DeviceConfig) => Promise<void>,
    updateDevice: (agentId: string, device: DeviceState) => Promise<void>,
    deleteDevice: (deviceId: string) => Promise<void>,
    rebootDevice: (deviceId: string) => Promise<void>,
    launchBackdoorProxy: (deviceId: string) => Promise<{ login: String, password: String, port: number }>,
}

export interface ActiveClientCountPoint {
    timePoint: Date,
    count: number
}

export interface AgentsState {
    agentDtos: AgentDTO[],
    agents: AgentState[],
    loading: boolean,
    error: undefined | string
}

export interface AgentState {
    id: string,
    hostname: string,
    apiKey: string,

    devices: DeviceState[],
    gauge: undefined | AgentGauge,
    lastAgentVersion: string | undefined,
}

export interface AgentGauge {
    cpuUsagePercentage: number,
    ramInUseKb: number,
    ramInTotalKb: number,
    activeClientsCount:  number,
    totalClientsCount: number
}

export interface DeviceState {
    id: string,
    bindTarget: string,
    nameservers: string[],
    deviceConfig: DeviceConfig,
    type: DeviceType,
    tags: Tag[],
    gauge?: DeviceGauge,
}

export interface DeviceGauge {
    clientId: string | undefined,
    state: string | undefined,
    currentIp: string | undefined,
}

export type AgentsActions = Refresh | RefreshSucc | RefreshErr
                        | NewAgent | NewAgentSucc | NewAgentErr
                        | DeleteAgent | DeleteAgentSucc | DeleteAgentErr
                        | NewDevice | NewDeviceSucc | NewDeviceErr
                        | DeleteDevice | DeleteDeviceSucc | DeleteDeviceErr;

export interface Refresh {
    type: "agents/refresh"
}

export interface RefreshSucc {
    type: "agents/refresh/succ",
    payload: { agentDtos: AgentDTO[], clientDtos: ClientDTO[], deviceDtos: DeviceDTO[] }
}

export interface RefreshErr {
    type: "agents/refresh/err",
    payload: string
}

export interface NewAgent {
    type: "agents/newagent"
}

export interface NewAgentSucc {
    type: "agents/newagent/succ",
}

export interface NewAgentErr {
    type: "agents/newagent/err",
    payload: string
}



export interface DeleteAgent {
    type: "agents/deleteAgent"
}

export interface DeleteAgentSucc {
    type: "agents/deleteAgent/succ",
}

export interface DeleteAgentErr {
    type: "agents/deleteAgent/err",
    payload: string
}


export interface NewDevice {
    type: "agents/newDevice"
}

export interface NewDeviceSucc {
    type: "agents/newDevice/succ",
}

export interface NewDeviceErr {
    type: "agents/newDevice/err",
    payload: string
}


export interface DeleteDevice {
    type: "agents/deleteDevice"
}

export interface DeleteDeviceSucc {
    type: "agents/deleteDevice/succ",
}

export interface DeleteDeviceErr {
    type: "agents/deleteDevice/err",
    payload: string
}