import {Box, Button, Container, Stack} from "@mui/material";
import {AgentsTable} from "./AgentsTable";
import {useCallback, useEffect, useMemo} from "react";
import {
    ActiveClientCountPoint,
    AgentsActions,
    AgentsState,
    DeviceConfig,
    DeviceModel, DeviceState, DeviceTypeVals
} from "../../types/agents";
import {
    AgentApi,
    ClientApi,
    Configuration,
    DeviceApi,
    DeviceDTO,
    StatisticsApi
} from "../../api";
import {useImmer, useImmerReducer} from "use-immer";
import {NewAgentModal} from "./NewAgentModal";
import {NotificationsBar} from "../notifications/NotificationsBar";
import _ from "lodash";
import StorageIcon from '@mui/icons-material/Storage';
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {MenuItem, SideBar} from "../side_bar/SideBar";
import moment from "moment";
import {Tag} from "../../types/common";

export function Agents({signOut, apiKey}: AgentsProps) {
    const basePath = process.env.REACT_APP_HOSTNAME_VAR;

    const agentApi = useMemo(() => new AgentApi(new Configuration({
        basePath,
        apiKey: apiKey
    })), [basePath, apiKey]);

    const deviceApi = useMemo(() => new DeviceApi(new Configuration({
        basePath,
        apiKey: apiKey
    })), [basePath, apiKey]);

    const clientApi = useMemo(() => new ClientApi(new Configuration({
        basePath,
        apiKey: apiKey
    })), [basePath, apiKey])

    const statisticsApi = useMemo(() => new StatisticsApi(new Configuration({
        basePath,
        apiKey: apiKey
    })), [basePath, apiKey])

    const menuItems: MenuItem[] = [
        {
            icon: <StorageIcon/>,
            path: "/agents",
            text: "Агенты",
            onClick: null
        },
        {
            icon: <PersonIcon/>,
            path: "/clients",
            text: "Клиенты",
            onClick: null
        },
        {
            icon: <ExitToAppIcon/>,
            path: "/",
            text: "Выход",
            onClick: () => {
                signOut();
            }
        }
    ]

    const [state, dispatch] = useImmerReducer<AgentsState, AgentsActions>((st, action) => {
        switch (action.type) {
            case "agents/refresh":
                st.loading = true
                break;
            case "agents/refresh/err":
                st.agentDtos = []
                st.agents = []
                st.loading = false;
                st.error = action.payload
                break;
            case "agents/refresh/succ":
                const agentDtos = action.payload.agentDtos;
                const clientsDtos = action.payload.clientDtos;
                const deviceDtos = action.payload.deviceDtos;

                //group devices by agent id
                const devicesByAgentId = _.groupBy(deviceDtos, d => d.agentId);
                const clientsByAgentId = _.groupBy(clientsDtos, c => c.agentId);

                st.loading = false;
                st.agentDtos = agentDtos;
                st.agents = action.payload.agentDtos.map(a => {
                    const agentId = a.id as string
                    const totalClientsCount = (clientsByAgentId[agentId] || []).length
                    const activeClientCount =
                        (clientsByAgentId[agentId] || [])
                            .filter(cl => cl.currentIp !== null)
                            .length
                    return ({
                        id: agentId,
                        hostname: a.hostname,
                        apiKey: a.apiKey,
                        lastAgentVersion: a.lastAgentVersion,
                        gauge: a.gauge ? {
                            cpuUsagePercentage: a.gauge.cpuUsagePercentage as number,
                            ramInUseKb: a.gauge.ramInUseKb as number,
                            ramInTotalKb: a.gauge.ramTotalKb as number,
                            activeClientsCount:  activeClientCount,
                            totalClientsCount: totalClientsCount
                        } : undefined,
                        devices: (devicesByAgentId[agentId] || [] as DeviceDTO[]).map(dt => {
                            return {
                                id: dt.id as string,
                                bindTarget: dt.bindTarget,
                                nameservers: dt.nameservers ?? [],
                                type:
                                    "hiLink17" in dt.deviceConfig && dt.deviceConfig?.hiLink17 ? DeviceTypeVals.HiLink17 :
                                        "antenity17" in dt.deviceConfig && dt.deviceConfig?.antenity17 ? DeviceTypeVals.Antenity17 :
                                            "hiLink21" in dt.deviceConfig && dt.deviceConfig?.hiLink21 ? DeviceTypeVals.HiLink21: DeviceTypeVals.Noop,
                                deviceConfig: dt.deviceConfig as DeviceConfig,
                                tags: dt.tags as Tag[],
                                gauge: dt.gauge ? {
                                    clientId: dt.gauge.clientId,
                                    state: dt.gauge.state as string,
                                    currentIp: dt.gauge.currentIp
                                } : undefined
                            }
                        }).sort((a,b) => a.bindTarget.localeCompare(b.bindTarget)),

                    })
                }).sort((a,b) => a.hostname.localeCompare(b.hostname));
                break;

            case "agents/newagent":
                st.loading = true
                break;
            case "agents/newagent/err":
                st.agentDtos = []
                st.agents = []
                st.loading = false;
                st.error = action.payload
                break;
            case "agents/newagent/succ":
                st.loading = false;
                break;

            case "agents/deleteAgent":
                st.loading = true;
                break;
            case "agents/deleteAgent/succ":
                st.loading = false;
                break;
            case "agents/deleteAgent/err":
                st.loading = false;
                st.error = action.payload;
                break;

            case "agents/newDevice":
                st.loading = true;
                break;
            case "agents/newDevice/succ":
                st.loading = false;
                break;
            case "agents/newDevice/err":
                st.loading = false;
                st.error = action.payload;
                break;

            case "agents/deleteDevice":
                st.loading = true;
                break;
            case "agents/deleteDevice/succ":
                st.loading = false;
                break;
            case "agents/deleteDevice/err":
                st.loading = false;
                st.error = action.payload;
                break;

        }
    }, {
        agentDtos: [],
        agents: [],
        loading: false,
        error: undefined
    });

    const [isNewAgentModalOpen, setIsNewAgentModalOpen] = useImmer(false);

    const refresh = useCallback(async () => {
        dispatch({type: "agents/refresh"});
        try {
            let agentsProm = agentApi.getAgents();
            let devicesProm = deviceApi.getDevices();
            let clientsProm = clientApi.getClients();

            //await for all promises
            let [agentResponse, deviceResponse, clientsResponse] = await Promise.all([agentsProm, devicesProm, clientsProm]);

            return dispatch({type: "agents/refresh/succ",
                payload: {
                    agentDtos: agentResponse.data,
                    clientDtos: clientsResponse.data,
                    deviceDtos: deviceResponse.data,
                }
            })
        } catch (e) {
            dispatch({type: "agents/refresh/err", payload: "Произошла ошибка."})
        }
    }, [agentApi, clientApi, deviceApi, dispatch])

    const newAgent = async (apiKey: string, hostname: string) => {
        dispatch({type: "agents/newagent"});
        try {
            await agentApi.createAgent({hostname, apiKey});
            dispatch({type: "agents/newagent/succ"});
            await refresh();
        } catch (e) {
            dispatch({type: "agents/newagent/err", payload: "Произошла ошибка."})
        }
    }

    const deleteAgent = async (id: string) => {
        dispatch({type: "agents/deleteAgent"});
        try {
            await agentApi.deleteAgent(id);
            dispatch({type: "agents/deleteAgent/succ"});
            await refresh();
        } catch (e) {
            dispatch({type: "agents/deleteAgent/err", payload: "Произошла ошибка."})
        }
    }

    const updateDevice = async (agentId: string, device: DeviceState) => {
        dispatch({type: "agents/newDevice"});
        try {
            await deviceApi.updateDevice({
                ...device,
                agentId: agentId,
                gauge: undefined,
            });
            dispatch({type: "agents/newDevice/succ"});
            await refresh();
        } catch (e) {
            dispatch({type: "agents/newDevice/err", payload: "Произошла ошибка."})
        }
    }

    const newDevice = async (id: string, bindTarget: string, nameservers: string[], tags: Tag[], deviceConfig: DeviceConfig) => {
        dispatch({type: "agents/newDevice"});
        try {

            await deviceApi.createDevice({
                bindTarget,
                nameservers,
                tags,
                deviceConfig,
                agentId: id,
            });
            dispatch({type: "agents/newDevice/succ"});
            await refresh();
        } catch (e) {
            dispatch({type: "agents/newDevice/err", payload: "Произошла ошибка."})
        }
    }


    const deleteDevice = async (deviceId: string) => {
        dispatch({type: "agents/deleteDevice"});
        try {
            await deviceApi.deleteDevice(deviceId);
            dispatch({type: "agents/deleteDevice/succ"});
            refresh();
        } catch (e) {
            dispatch({type: "agents/deleteDevice/err", payload: "Произошла ошибка."})
        }
    }

    const fetchActiveClientsData  = async (agentId: string) => {
        try {
            let resp = await statisticsApi.getActiveClientData(agentId, 12);
            return resp.data.map(d => {
                return {
                    timePoint: moment(d.timePoint, "dd-MM-yyyy HH:mm:ss").toDate(),
                    count: d.count
                } as ActiveClientCountPoint;
            });
        } catch (e) {
            return [];
        }

    }

    const launchBackdoorProxy = async (deviceId: string) => {
        let res = await deviceApi.openBackdoorProxy(deviceId);
        return {
            login: res.data.login,
            password: res.data.password,
            port: res.data.port,
        };
    }

    const rebootDevice = async (deviceId: string) =>  {
        await deviceApi.reboot(deviceId)
    }

    useEffect(() => { refresh().catch(console.error) }, [refresh]);

    const deviceModel: DeviceModel = {
        updateDevice,
        newDevice,
        deleteDevice,
        launchBackdoorProxy,
        rebootDevice,
    }

    return (
            <Box display="flex">
                <SideBar title="Proxybox" menuItems={menuItems}/>
                <NewAgentModal isOpen={isNewAgentModalOpen} onSend={(apiKey, hostname) => {
                    newAgent(hostname, apiKey);
                    setIsNewAgentModalOpen(false);
                }} close={() => setIsNewAgentModalOpen(false)} />

                <Container maxWidth={false}>
                    <NotificationsBar show={state.error !== undefined} msg={state.error as string}/>
                    <div style={{ "height" : "75px", "width" : "100%", backgroundColor: "white", borderBottom: "solid", position: "fixed", padding:0, margin: 0, top: 0, "zIndex": 5}}>
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={2}
                            >
                            <Button variant="contained"
                                    sx={{ marginY: 2 }}
                                    onClick={() => setIsNewAgentModalOpen(true)}>Новый агент</Button>
                            <Button variant="contained"
                                    sx={{marginY: 2}}
                                    onClick={() => {
                                        refresh()
                                    }}>Обновить</Button>
                        </Stack>

                    </div>
                    <div style={{ "height" : "75px", "width" : "100%", backgroundColor: "white", padding:0, margin: 0, top: 0}}>
                    </div>

                    <AgentsTable deviceModel={deviceModel} deleteAgent={deleteAgent} agents={state.agents} fetchActiveClientsData={fetchActiveClientsData}/>
                </Container>
            </Box>
    )
}

interface AgentsProps {
    apiKey: string,
    signOut: () => void
}