import {
    Button, DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    Stack,
    TextField
} from "@mui/material";
import {SubmitHandler, useForm, FormProvider, useFormContext} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ipRegex from 'ip-regex';
import {ProxyDialog} from "../components/ProxyDialog";
import {useEffect} from "react";
import {DeviceTypeVals, DeviceType, HiLink17, HiLink21, DeviceConfig, DeviceState, Antenity17} from "../../types/agents"
import {extractFirstErr, fromPrettyTag, isValidTagString, prettyTag, Tag} from "../../types/common";
import { Controller } from "react-hook-form";
import ArrayInput from "../components/ArrayInput";

export interface NewDeviceModalProps {
    title: string
    isOpen: boolean,
    close: () => void,
    agentId: string,
    defaults?: DeviceState,
    onSend: (agentId: string, bindTarget: string, nameservers: string[], tags: Tag[], deviceType: DeviceType, config: DeviceConfig) => void,
}

type DeviceModalForm = {
    bindTarget: string,
    tags: string[],
    deviceType: DeviceType,
    antenity17?: HiLink17,
    hiLink17?: HiLink17,
    hiLink21?: HiLink21,
    nameservers: string[],
}

const ni_regexp = new RegExp("^[0-9a-zA-Z-:]+$");

const newDeviceModalScheme = yup.object().shape({
    bindTarget:
        yup.string().required().test("bt", "Ожидается валидный ip v4 адрес или сетевой интерфейс", (v, ctx) => {
            if (v === undefined) {
                return false
            } else {
                return ipRegex.v4({exact: true}).test(v) || ni_regexp.test(v)
            }
        }).required(),
    nameservers: yup.array()
        .of(
            yup.string().matches(ipRegex.v4({exact: true}), "Ожидается валидный ip v4 адрес")
        ),
    antenity17: yup.object({
        webAddr: yup.string().matches(ipRegex.v4({exact: true}), "Ожидается валидный ip v4 адрес")
    }).notRequired(),
    hiLink17: yup.object({
        webAddr: yup.string().matches(ipRegex.v4({exact: true}), "Ожидается валидный ip v4 адрес")
    }).notRequired(),
    hiLink21: yup.object({
        webAddr: yup.string().matches(ipRegex.v4({exact: true}), "Ожидается валидный ip v4 адрес"),
        login: yup.string().max(150, "Длинна логина не должна привышать 150 символов").notRequired(),
        password: yup.string().max(150, "Длинна пароля не должна привышать 150 символов").notRequired(),
    }).notRequired(),
    tags: yup.array()
        .of(
            yup.string()
                .min(1, "Пустая строка не может быть тегом")
                .matches(/^[a-zA-Z0-9:\s]*$/, "Тэги должны состоять только из латинских букв и цифр, разделенные пробелом или двоеточием")
                .max(150, "Длинна строки не должна привышать 150 символов")
                .test("isValid", "Каждый тег может быть быть вида tagValue или tagKey:tagValue", (tag) => tag !== undefined && isValidTagString(tag))
        ),
});

export function NewDeviceModal({title, isOpen, close, onSend, agentId, defaults}: NewDeviceModalProps) {
    const methods = useForm<DeviceModalForm>({
        resolver: yupResolver(newDeviceModalScheme),
        defaultValues: {
            bindTarget: defaults?.bindTarget,
            nameservers: defaults?.nameservers ?? [],
            tags: defaults?.tags.map(prettyTag) ?? [],
            deviceType: defaults?.type ?? DeviceTypeVals.Noop,
            hiLink17: defaults?.deviceConfig && "hiLink17" in defaults.deviceConfig && defaults.deviceConfig.hiLink17 ? defaults.deviceConfig.hiLink17 : undefined,
            antenity17: defaults?.deviceConfig && "antenity17" in defaults.deviceConfig && defaults.deviceConfig.antenity17 ? defaults.deviceConfig.antenity17 : undefined,
            hiLink21: defaults?.deviceConfig && "hiLink21" in defaults.deviceConfig && defaults.deviceConfig.hiLink21 ? defaults.deviceConfig.hiLink21 : undefined,
        }
    });

    const { register, handleSubmit, formState: { errors }, watch, resetField, control } = methods;
    const watchDeviceType = watch("deviceType")

    useEffect(() => {
        resetField("antenity17");
        resetField("hiLink17");
        resetField("hiLink21")
    }, [resetField, watchDeviceType])

    const onSubmit: SubmitHandler<DeviceModalForm> = data => {
        let deviceConfig: DeviceConfig;
        switch (data.deviceType) {
            case DeviceTypeVals.Noop:
                deviceConfig = {
                    "noop": {},
                }
                break;
            case DeviceTypeVals.Antenity17:
                deviceConfig = {
                    "antenity17": data.antenity17 as Antenity17,
                }
                break;
            case DeviceTypeVals.HiLink17:
                deviceConfig = {
                    "hiLink17": data.hiLink17 as HiLink17,
                }
                break;
            case DeviceTypeVals.HiLink21:
                deviceConfig = {
                    "hiLink21": data.hiLink21 as HiLink21,
                }
                break;
        }

        onSend(
            agentId,
            data.bindTarget,
            data.nameservers,
            data.tags.map(tg => fromPrettyTag(tg) as Tag), // safe as prior to submit validation is triggered,
            data.deviceType,
            deviceConfig
        )
        close()
    }

    return (
        <ProxyDialog open={isOpen} onClose={close}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>
                    {title}
                </DialogTitle>
                <DialogContent>
                    <Stack alignItems="stretch" spacing={2}>
                        <TextField
                            id="outlined-basic"
                            label="IPv4 or NI"
                            variant="outlined"
                            inputProps={register("bindTarget")}
                            error={errors.bindTarget !== undefined}
                            helperText={errors.bindTarget?.message}
                            required
                        />

                        <Select value={watchDeviceType} inputProps={register("deviceType")} fullWidth>
                            <MenuItem value={DeviceTypeVals.Noop}>Noop</MenuItem>
                            <MenuItem value={DeviceTypeVals.HiLink17}>HiLink 17</MenuItem>
                            <MenuItem value={DeviceTypeVals.Antenity17}>HiLink 17A</MenuItem>
                            <MenuItem value={DeviceTypeVals.HiLink21}>HiLink 21</MenuItem>
                        </Select>

                        <Controller
                            name="nameservers"
                            control={control}
                            render={({
                                field: {onChange, value},
                                fieldState: { error }
                            }) => (
                                <ArrayInput
                                    onChange={onChange}
                                    value={value}
                                    placeholder=""
                                    label="Nameservers"
                                    error={extractFirstErr(error)}
                                />
                            )}
                        />

                        <FormProvider {...methods} >
                            {watchDeviceType === DeviceTypeVals.HiLink17 && <HiLinkForm17/>}
                            {watchDeviceType === DeviceTypeVals.Antenity17 && <Antenity17Form/>}
                            {watchDeviceType === DeviceTypeVals.HiLink21 && <HiLinkForm21/>}
                        </FormProvider>

                        <Controller
                            name="tags"
                            control={control}
                            render={({
                                         field: {onChange, value},
                                         fieldState: { error }
                                     }) => (
                                <ArrayInput
                                    onChange={onChange}
                                    value={value}
                                    placeholder=""
                                    label="Теги"
                                    error={extractFirstErr(error)}
                                />
                            )}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" variant="contained" color="success">Добавить</Button>
                </DialogActions>
            </form>
        </ProxyDialog>
    )
}

function Antenity17Form() {
    const { register, getFieldState } = useFormContext<DeviceModalForm>();
    const webAddrErr = getFieldState("antenity17.webAddr").error?.message;
    return (
        <Stack alignItems="stretch" spacing={2}>
            <TextField
                id="outlined-basic"
                label="WebAPI"
                variant="outlined"
                inputProps={register("antenity17.webAddr")}
                error={webAddrErr !== undefined}
                helperText={webAddrErr}
                fullWidth
            />
        </Stack>
    )
}

function HiLinkForm17() {
    const { register, getFieldState } = useFormContext<DeviceModalForm>();
    const webAddrErr = getFieldState("hiLink17.webAddr").error?.message;
    return (
        <Stack alignItems="stretch" spacing={2}>
            <TextField
                id="outlined-basic"
                label="WebAPI"
                variant="outlined"
                inputProps={register("hiLink17.webAddr")}
                error={webAddrErr !== undefined}
                helperText={webAddrErr}
                fullWidth
            />
        </Stack>
    )
}

function HiLinkForm21() {
    const { register, getFieldState } = useFormContext<DeviceModalForm>();
    const webAddrErr = getFieldState("hiLink21.webAddr").error?.message;
    const loginErr = getFieldState("hiLink21.login").error?.message;
    const passwordErr = getFieldState("hiLink21.password").error?.message;

    return (
        <Stack alignItems="stretch" spacing={2}>
            <TextField
                id="outlined-basic"
                label="WebAPI"
                variant="outlined"
                inputProps={register("hiLink21.webAddr")}
                error={webAddrErr !== undefined}
                helperText={webAddrErr}
                fullWidth
            />

            <TextField
                id="outlined-basic"
                label="Login"
                variant="outlined"
                inputProps={register("hiLink21.login")}
                error={loginErr !== undefined}
                helperText={loginErr}
                fullWidth
            />

            <TextField
                id="outlined-basic"
                label="Password"
                variant="outlined"
                inputProps={register("hiLink21.password")}
                error={passwordErr !== undefined}
                helperText={passwordErr}
                fullWidth
            />
        </Stack>
    )
}