import {
    Button, Card, Chip, Collapse, Grid,
    IconButton, Link, Stack,
    styled,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow, Tooltip, Typography
} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useImmer } from "use-immer";
import CircleIcon from '@mui/icons-material/Circle';
import {
    ActiveClientCountPoint,
    AgentState,
    DeviceModel,
    DeviceState, deviceTypePretty,
} from "../../types/agents";
import { NewDeviceModal } from "./NewDeviceModal";
import { StatisticsModal } from "./StatisticsModal";
import {Delete, Edit, RestartAlt} from "@mui/icons-material";
import {ConfirmDialog} from "../components/ConfirmDialog";
import BuildIcon from '@mui/icons-material/Build';
import {AckDialog} from "../components/AckDialog";
import {useState} from "react";
import {chooseColor, prettyTag} from "../../types/common";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "grey",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

export interface AgentsTableProps {
    agents: AgentState[],
    deleteAgent: (id: string) => void,
    deviceModel: DeviceModel,
    fetchActiveClientsData: (agentId: string) => Promise<ActiveClientCountPoint[]>,
}

export function AgentsTable({ agents, deviceModel, deleteAgent, fetchActiveClientsData }: AgentsTableProps) {
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCell />
                        <StyledTableCell align="left">Имя Хоста</StyledTableCell>
                        <StyledTableCell align="left">Апи Ключ</StyledTableCell>
                        <StyledTableCell align="left">Версия агента</StyledTableCell>
                        <StyledTableCell align="left">Активные клиенты</StyledTableCell>
                        <StyledTableCell align="left">Устройства</StyledTableCell>
                        <StyledTableCell align="left">Статус</StyledTableCell>
                        <StyledTableCell align="left">Действия</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        agents.map((a) => {
                            return (
                                <AgentRow key={a.id}
                                          agent={a}
                                          deleteAgent={() => {
                                              deleteAgent(a.id)
                                          }}
                                          fetchActiveClientsData={fetchActiveClientsData}
                                          deviceModel={deviceModel}
                                />
                            )
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export interface AgentRowProps {
    agent: AgentState,
    deleteAgent: () => void,
    fetchActiveClientsData: (agentId: string) => Promise<ActiveClientCountPoint[]>,
    deviceModel: DeviceModel,
}

function AgentRow({ agent, deleteAgent, deviceModel, fetchActiveClientsData }: AgentRowProps) {
    const [newAgentModalOpen, setNewAgentModalOpen] = useImmer(false);
    const [newDeviceModalOpen, setNewDeviceModalOpen] = useImmer(false);
    const [statisticsModalOpen, setStatisticsModalOpen] = useImmer(false);
    const [isDeleteAgentModalOpen, setIsDeleteAgentModalOpen] = useImmer(false);
    const clientInUse = agent.gauge?.activeClientsCount;
    const clientTotal = agent.gauge?.totalClientsCount;
    const activeDevices = agent.devices.filter(d => !!d.gauge?.currentIp).length;
    const devicesTotal = agent.devices.length;

    const deleteAgentConfirmation = `Вы действительно хотите удалить агент ${agent.hostname}?`
    return (
        <>
            {statisticsModalOpen && <StatisticsModal agentId={agent.id} isOpen={statisticsModalOpen} close={() => {
                setStatisticsModalOpen(false)
            }} fetchActiveClientsData={fetchActiveClientsData} />}
            <TableRow>
                <ConfirmDialog open={isDeleteAgentModalOpen} text={deleteAgentConfirmation} onClose={(res) => {
                    if (res) {
                        deleteAgent()
                    }
                    setIsDeleteAgentModalOpen(false)
                }}/>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setNewAgentModalOpen(!newAgentModalOpen)}>
                        {newAgentModalOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    {agent.hostname}
                </TableCell>
                <TableCell>
                    {agent.apiKey}
                </TableCell>
                <TableCell>
                    {agent.lastAgentVersion}
                </TableCell>
                <TableCell>
                    <Link href="#" onClick={() => setStatisticsModalOpen(true)}>
                        {clientInUse} / {clientTotal}
                    </Link>
                </TableCell>
                <TableCell>
                    {activeDevices} / {devicesTotal}
                </TableCell>
                <TableCell>
                    <CircleIcon fontSize="small" color={agent.gauge === undefined ? "error" : "success"} />
                </TableCell>
                <TableCell>
                    <Tooltip title="Удалить">
                        <IconButton color={"error"}  onClick={(e) => setIsDeleteAgentModalOpen(true)}>
                            <Delete/>
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={newAgentModalOpen}>
                        <Stack sx={{ m: 1.5 }} justifyContent="space-evenly" alignItems="stretch" spacing={1.5}>
                            <Card style={{ padding: 10 }}>
                                <Stack justifyContent="center" alignItems="flex-start">
                                    <Typography variant="h6">Метрики </Typography>
                                    <Typography
                                        variant="body2">CPU: {agent.gauge ? agent.gauge.cpuUsagePercentage : 0}% </Typography>
                                    <Typography variant="body2">
                                        ОЗУ: {agent.gauge ? agent.gauge.ramInUseKb : 0}Kb
                                        / {agent.gauge ? agent.gauge.ramInTotalKb : 0}Kb
                                    </Typography>
                                </Stack>
                            </Card>
                            <Card style={{ padding: 10 }}>
                                <NewDeviceModal title={"Добавить устройство"} onSend={(agentId, currentIp, nameservers, tags, deviceType, deviceConfig) => {
                                    deviceModel.newDevice(agentId, currentIp, nameservers, tags, deviceConfig);
                                }} close={() => {
                                    setNewDeviceModalOpen(false)
                                }} isOpen={newDeviceModalOpen} agentId={agent.id} />
                                <Button style={{ marginTop: 5, marginBottom: 5 }} variant={"contained"}
                                    onClick={(e) => setNewDeviceModalOpen(true)}>Новое устройство</Button>

                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>IP</StyledTableCell>
                                            <StyledTableCell>Тип устройства</StyledTableCell>
                                            <StyledTableCell>Текущий IP</StyledTableCell>
                                            <StyledTableCell>DNS сервера</StyledTableCell>
                                            <StyledTableCell>Теги</StyledTableCell>
                                            <StyledTableCell>Действия</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            agent.devices.map((d) => {
                                                return <DeviceRow key={d.id} device={d} agentId={agent.id} agentHostname={agent.hostname} deviceModel={deviceModel} />
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </Card>
                        </Stack>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

const DeviceRow = ({agentId, device, deviceModel, agentHostname}: { deviceModel: DeviceModel, agentId: string, agentHostname: string, device: DeviceState}) => {
    const [isAckModalOpen, setAckModalOpen] = useState(false)
    const [isEditModalOpen, setEditModalOpen] = useState(false)
    const [ackText, setAckText] = useState("")

    return (
        <TableRow>
            <NewDeviceModal
                title={"Редактировать устройство"}
                isOpen={isEditModalOpen}
                close={() => setEditModalOpen(false)}
                agentId={agentId}
                defaults={device}
                onSend={(agentId, bindTarget, nameservers, tags, dt, cfg) => {
                    deviceModel.updateDevice(agentId, {
                        id: device.id,
                        bindTarget: bindTarget,
                        nameservers: nameservers,
                        tags: tags,
                        deviceConfig: cfg,
                        type: dt,
                    })
                }}
            />
            <AckDialog isOpen={isAckModalOpen} onClick={setAckModalOpen} text={ackText}/>
            <TableCell>{device.bindTarget}</TableCell>
            <TableCell>{deviceTypePretty[device.type]}</TableCell>
            <TableCell>{device.gauge === undefined ? <CircleIcon color={"error"} /> : device.gauge.currentIp}</TableCell>
            <TableCell>
                <Grid container rowSpacing={0.5} columnSpacing={0.5}>
                    {
                        Array.from(device.nameservers?.values() || []).map((ns, idx) => {
                            return (
                                <Grid key={idx} item>
                                    <Chip label={ns} color="default" />
                                </Grid>
                            );
                        }) || []
                    }
                </Grid>

            </TableCell>
            <TableCell>
                <Grid container rowSpacing={0.5} columnSpacing={0.5}>
                    {
                        Array.from(device.tags?.values() || []).map((t) => {
                            const tag = prettyTag(t)
                            return (
                                <Grid item>
                                    <Chip key={tag} label={tag} sx={{bgcolor: chooseColor(tag), color: "white"}} />
                                </Grid>
                            );
                        }) || []
                    }
                </Grid>

            </TableCell>
            <TableCell>
                <Stack spacing={1.5} direction="row" justifyContent="flex-end">
                    <Tooltip title="Редактировать">
                        <IconButton onClick={(e) => {
                            setEditModalOpen(true);
                        }}>
                            <Edit/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Отладка девайса">
                        <IconButton onClick={() => {
                            deviceModel.launchBackdoorProxy(device.id)
                                .then(e => {
                                    setAckText(`
                                        Отладочный прокси открыт с доступами: 
                                        ${agentHostname}:49001:${e.login}:${e.password}
                                        socks://${e.login}:${e.password}@${agentHostname}:49001?title=${e.login}
                                    `)
                                    setAckModalOpen(true)
                                }).catch(e => {
                                    console.log(e)
                                    setAckText(`Не удалось запустить отладочные прокси`)
                                    setAckModalOpen(true)
                                })
                        }}>
                            <BuildIcon fontSize="small"/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Ребут">
                        <IconButton onClick={() => {
                            deviceModel.rebootDevice(device.id).then(_ => {
                                setAckText(`Устройство перезапущенно`)
                                setAckModalOpen(true)
                            }).catch(e => {
                                console.log(e)
                                setAckText(`Не удалось перезапустить устройство`)
                                setAckModalOpen(true)
                            })
                        }}>
                            <RestartAlt/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Удалить">
                        <IconButton color={"error"} onClick={() => {
                            deviceModel.deleteDevice(device.id)
                        }}>
                            <Delete/>
                        </IconButton>
                    </Tooltip>
                </Stack>
            </TableCell>
        </TableRow>
    )
}