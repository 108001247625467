import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField
} from "@mui/material";
import {v4 as uuidv4} from 'uuid';
import {SubmitHandler, useForm} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {ProxyDialog} from "../components/ProxyDialog";

type NewAgentForm = {
    hostname: string,
}

const newAgentFormScheme = yup.object().shape({
    hostname: yup.string()
        .required("Обязательное поле")
        .max(100, "Название хоста не должно привышать 100 символов"),
});

export function NewAgentModal({isOpen, close, onSend}: NewAgentModalProps) {
    const { register, handleSubmit, formState: { errors } } = useForm<NewAgentForm>({
        resolver: yupResolver(newAgentFormScheme),
    })

    const onSubmit: SubmitHandler<NewAgentForm> = data => {
        onSend(data.hostname, uuidv4())
        close()
    }

    return (
        <ProxyDialog open={isOpen} onClose={close}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>Добавить агент</DialogTitle>
                <DialogContent>
                    <TextField
                        id="outlined-basic"
                        label="Имя хоста"
                        variant="outlined"
                        inputProps={register("hostname")}
                        error={errors.hostname !== undefined}
                        helperText={errors.hostname?.message}
                        required
                    />
                </DialogContent>
                <DialogActions>
                    <Button type="submit" variant="contained" color="success">Добавить</Button>
                </DialogActions>
            </form>
        </ProxyDialog>
    )
}

export interface NewAgentModalProps {
    isOpen: boolean,
    close: () => void,
    onSend: (hostname: string, apiKey: string) => void,
}