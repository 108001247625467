import {
    Chip,
    IconButton,
    Stack,
    styled,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from "@mui/material";
import {ClientState, Os} from "../../types/clients";
import {useImmer} from "use-immer";
import {AssignAgentModal} from "./AssignAgentModal";
import {AgentDTO} from "../../api";
import {NewClientModal} from "./NewClientModal";
import {bps_to_mbps} from "./utils";
import moment from "moment";
import {Assignment, Delete, Edit, Refresh} from "@mui/icons-material";
import {ConfirmDialog} from "../components/ConfirmDialog";
import {v4} from "uuid";
import {chooseColor, prettyTag, Tag} from "../../types/common";

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "grey",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

export interface ClientsTableProps {
    clients: ClientState[],
    deleteClient: (id: string) => void,
    updateClient: (cl: { id: string, username: string, password: string, readLimit: number, writeLimit: number, connectionLimit: number, tags: Tag[], changeIpUid: string, os: Os, expiresAt: string | undefined, changeIpDelaySec: number | undefined, agentId: string | undefined }) => Promise<void>,
    changeIpDelay: (id: string, changeIpDelay: number) => void,
    assignAgent: (clientId: string, agentId: string) => void,
    agentDtos: AgentDTO[]
}

export function ClientsTable({clients, updateClient, deleteClient, assignAgent, agentDtos}: ClientsTableProps) {
    const [assignAgentModal, setAssignAgentModal] = useImmer({
        isOpen: false,
        clientId: "unassign"
    });

    return (
        <>
            <AssignAgentModal close={() => setAssignAgentModal({isOpen: false, clientId: "unassign"})}
                              isOpen={assignAgentModal.isOpen} onSend={(agentId) => {
                assignAgent(assignAgentModal.clientId as string, agentId)
            }} agentDtos={agentDtos}/>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Логин</StyledTableCell>
                            <StyledTableCell>Пароль</StyledTableCell>
                            <StyledTableCell>Лимиты</StyledTableCell>
                            <StyledTableCell>Агент</StyledTableCell>
                            <StyledTableCell>IP</StyledTableCell>
                            <StyledTableCell>Теги</StyledTableCell>
                            <StyledTableCell>Действия</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            [...clients]
                                .sort((a, b) => a.username.localeCompare(b.username, 'en', {
                                    numeric: true,
                                    sensitivity: 'base'
                                }))
                                .map(cl =>
                                    <ClientRow
                                        updateClient={(ucl: { username: string, password: string, readLimit: number, writeLimit: number, connectionLimit: number, tags: Tag[], os: Os, expiresAt: string | undefined, changeIpDelaySec: number | undefined }) =>
                                            updateClient({
                                                ...ucl,
                                                id: cl.id,
                                                changeIpUid: cl.changeIpUid,
                                                agentId: cl?.assignedAgent?.id
                                            })
                                        }
                                        resetChangeIpLink={() => {
                                            updateClient({
                                                ...cl,
                                                changeIpUid: v4(),
                                                agentId: cl?.assignedAgent?.id,
                                            })
                                        }}
                                        setAssignAgentModalClientId={(clientId) => {
                                            setAssignAgentModal(s => {
                                                s.clientId = clientId;
                                                s.isOpen = true
                                            })
                                        }}
                                        deleteClient={() => deleteClient(cl.id)}
                                        key={cl.id}
                                        client={cl}
                                    />
                                )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export interface ClientRowProps {
    client: ClientState,
    deleteClient: () => void,
    updateClient: (ucl: { username: string, password: string, readLimit: number, writeLimit: number, connectionLimit: number, tags: Tag[], os: Os, expiresAt: string | undefined, changeIpDelaySec: number | undefined }) => Promise<void>,
    resetChangeIpLink: () => void,
    setAssignAgentModalClientId: (clientId: string) => void
}

function ClientRow({
                       client,
                       updateClient,
                       deleteClient,
                       resetChangeIpLink,
                       setAssignAgentModalClientId
                   }: ClientRowProps) {
    const [isNewClientModalOpen, setIsNewClientModalOpen] = useImmer(false);
    const [isResetLinkModalOpen, setIsResetLinkModalOpen] = useImmer(false);
    const [isDeleteUserModalOpen, setDeleteUserModalOpen] = useImmer(false);

    const resetConfirmationText = `Вы действительно хотите сгенерировать новую ссылку для смены ip для ${client.username}?`
    const deleteConfirmationText = `Вы действительно хотите удалить клиента ${client.username}?`

    const clientWithIpDelayMin = {
        ...client,
        changeIpDelayMin: client.changeIpDelaySec / 60
    }

    return (
        <TableRow>
            <NewClientModal isOpen={isNewClientModalOpen} close={() => setIsNewClientModalOpen(false)}
                            title="Редактировать клиента"
                            defaults={clientWithIpDelayMin}
                            onSend={(username, password, readLimit, writeLimit, connectionLimit, tags, os, expiresAt, changeIpDelaySec) => {
                                updateClient({
                                    username,
                                    password,
                                    readLimit,
                                    writeLimit,
                                    connectionLimit,
                                    tags,
                                    os,
                                    expiresAt,
                                    changeIpDelaySec,
                                });
                            }}/>
            <ConfirmDialog open={isResetLinkModalOpen} text={resetConfirmationText} onClose={(res) => {
                if (res) {
                    resetChangeIpLink()
                }
                setIsResetLinkModalOpen(false)
            }}/>
            <ConfirmDialog open={isDeleteUserModalOpen} text={deleteConfirmationText} onClose={(res) => {
                if (res) {
                    deleteClient()
                }
                setDeleteUserModalOpen(false)
            }}/>
            <TableCell>{client.username}</TableCell>
            <TableCell>{client.password}</TableCell>
            <TableCell style={{width: '20%'}}>
                {client.readLimit > 0 &&
                    <Typography variant="body2">Read: {bps_to_mbps(client.readLimit)} Mbps</Typography>}
                {client.writeLimit > 0 &&
                    <Typography variant="body2">Write: {bps_to_mbps(client.writeLimit)} Mbps</Typography>}
                {client.connectionLimit > 0 &&
                    <Typography variant="body2">Connection limit: {client.connectionLimit}</Typography>}
                {client.os !== Os.Default && <Typography variant="body2">Fingerprint: {client.os}</Typography>}
                {client.expiresAt &&
                    (moment(client.expiresAt).isAfter(moment())
                            ? <Typography variant="body2">
                                Expires at: {client.expiresAt}
                            </Typography>
                            : <Typography color="tomato" variant="body2">
                                Expired at: {client.expiresAt}
                            </Typography>
                    )
                }
            </TableCell>
            <TableCell>{client.assignedAgent?.hostname || "Не назначен"}</TableCell>
            <TableCell>
                <Typography variant="body2">Ссылка смены IP: <br/> {client.changeIpUrl}</Typography>
                {client.currentIp &&
                    <Typography variant="body2"><br/>Текущий IP: <b>{client.currentIp}</b></Typography>}
                {client.changeIpDelaySec > 0 && <Typography variant="body2">Автосмена: <Typography variant="body2"
                                                                                                   color="blue">{client.changeIpDelaySec / 60} минут</Typography>
                </Typography>}
            </TableCell>
            <TableCell>
                <Stack>
                    {
                        client.tags.map((t, index) => {
                            const ptg = prettyTag(t);
                            return <Chip style={{marginBottom: 5}} key={ptg} label={ptg} sx={{bgcolor: chooseColor(ptg), color: "white"}}/>
                        })
                    }
                </Stack>
            </TableCell>
            <TableCell>
                <Stack direction="row" spacing={0.5}>

                    <Tooltip title="Редактировать">
                        <IconButton onClick={(e) => {
                            setIsNewClientModalOpen(true);
                        }}>
                            <Edit/>
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Обновить ссылку для смены ip">
                        <IconButton onClick={(e) => {
                            setIsResetLinkModalOpen(true);
                        }}>
                            <Refresh/>
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Назначить агент">
                        <IconButton onClick={(e) => {
                            setAssignAgentModalClientId(client.id)
                        }}>
                            <Assignment/>
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Удалить">
                        <IconButton color={"error"} onClick={e => {
                            setDeleteUserModalOpen(true)
                        }}>
                            <Delete/>
                        </IconButton>
                    </Tooltip>
                </Stack>
            </TableCell>
        </TableRow>
    )
}