import {ProxyDialog} from "./ProxyDialog";
import {Button, DialogActions, DialogContent, DialogContentText} from "@mui/material";

type ConfigDialogProps = {
    isOpen: boolean,
    onClick: (ns: boolean) => void,
    text: string,
}

export function AckDialog({isOpen, text, onClick}: ConfigDialogProps) {
    return (
        <ProxyDialog open={isOpen}>
            <DialogContent>
                <DialogContentText>{text}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClick(false)}>Ок</Button>
            </DialogActions>
        </ProxyDialog>
    )
}