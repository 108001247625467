import {Dialog, styled} from "@mui/material";

export const ProxyDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(1),
    },
    '& .MuiDialogActions-root': {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));